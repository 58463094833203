import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/details-layout"
import Seo from "../../components/seo"
import * as styles from "../../less/styles.scss"
import "./about.scss"

import About from "./components/about-us"

const AboutUs = () => (
  <Layout>
    <Seo title="D-Golden Tours| D-Golden Tours Info " />
    <About/>
  </Layout>
)

export default AboutUs;
